<template>
  <div id="ProductManage" class="section">
    <div class="columns is-multiline">
      <div class="column is-10-desktop is-10-tablet is-12-mobile">
        <h1 class="title">
          PRODUCT MANAGER
        </h1>
      </div>
      <div class="column is-12-mobile">
        <b-button
          expanded
          type="is-primary"
          icon-left="plus"
          @click.prevent="navigateToProductDetail"
        >
          Add Product
        </b-button>
      </div>
    </div>

    <div class="container">
      <product-table />
    </div>
  </div>
</template>

<script>
import ProductTable from "@/components/tables/ProductTable";

export default {
  name: "ProductManage",
  components: {
    ProductTable
  },
  methods: {
    navigateToProductDetail: function() {
      this.$router.push({
        name: "product-detail"
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#ProductManage {
  text-align: left;
}
.cover-center {
  object-position: center;
  object-fit: cover;
  height: 128px;
}
</style>
