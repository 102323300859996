<template>
  <div id="ProductTable">
    <b-table
      v-if="products"
      striped
      hoverable
      :data="products"
      style="cursor: pointer;"
      @click="navigateToProductDetail"
    >
      <template slot-scope="props">
        <b-table-column field="id" label="ID">
          {{ props.row._id }}
        </b-table-column>

        <b-table-column field="image" label="Image">
          <figure class="image is-128x128">
            <img class="cover-center" :src="props.row.main_image" />
          </figure>
        </b-table-column>

        <b-table-column field="name" label="Name">
          {{ props.row.name.en }}
        </b-table-column>

        <b-table-column field="price" label="Price">
          {{ props.row.price }}
        </b-table-column>

        <b-table-column field="stock" label="In Stock" centered>
          {{ props.row.instock }}
        </b-table-column>
        <b-table-column field="category" label="Category">
          {{ props.row.cat_name.en }}
        </b-table-column>
        <b-table-column field="preorder" label="Pre-Order" centered>
          <b-tag v-if="props.row.preorder" type="is-success">Available</b-tag>
          <b-tag v-else type="is-danger">Unavailable</b-tag>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";
import ProductApi from "@/apis/ProductApi";
import CategoryApi from "@/apis/CategoryApi";

export default {
  name: "ProductTable",
  data() {
    return {
      products: null,
      categories: []
    };
  },
  filters: {
    dateformatter: function(value) {
      if (!value) return "";
      return Helpers.dateFormatter(value);
    }
  },
  methods: {
    getProductList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApi.requestProductList();
      loadingComponent.close();
      if (response && response.length > 0) {
        this.products = response;
      }
      this.products.forEach(product => {
        const matched = this.categories.find(category => {
          return category._id === product.cat_id;
        });
        product.cat_name = matched.title;
      });
    },
    requestCategoryList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CategoryApi.requestCategoryList();
      loadingComponent.close();
      this.categories = response;
      this.getProductList();
    },
    navigateToProductDetail: function(selectedProduct) {
      this.$router.push({
        name: "product-detail",
        params: { product: selectedProduct }
      });
    }
  },
  mounted() {
    this.requestCategoryList();
  }
};
</script>

<style scoped>
.cover-center {
  object-position: center;
  object-fit: cover;
  height: 128px;
}
.clickable-icon {
  cursor: pointer;
  margin-right: 0.5rem;
}
</style>
